<template>
  <div class="main-resetPwd">
      <div class="views">
          <div class="title">重置密码</div>
          <div class="zh-title">新密码</div>
          <input class="input-title" type="password" v-model="newPwd" placeholder="请输入新密码">
          <div class="mm-title">确认密码</div>
          <input class="input-title" type="password" v-model="surePwd" placeholder="请输入确认密码">
          <div class="login" @click.stop="sureMethod()" :style="{backgroundColor:isSkin}">确认</div>
      </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
export default {
  data() {
    return {
      newPwd:'',
      surePwd:'',
      phone:''
    }
  },
  created(){
    this.phone = this.$route.query.phone
    this.$store.commit('changClick',1)
    var skin = sessionStorage.getItem('Skin')
    var zskin = sessionStorage.getItem('ZSkin')
    if (skin) {
        this.$store.commit('changSkin',skin)
    }
    if (zskin) {
        this.$store.commit('changZSkin',zskin)
    }
  },
  computed:{
    isSkin(){
		return this.$store.state.isSkin
    }
   },
    methods:{
      sureMethod(){
        var that = this
        if (that.newPwd == '') {
          Message({
            showClose: true,
            message: '请输入新密码！',
            type: 'error'
          })
          return
        }
        if (that.surePwd == '') {
          Message({
            showClose: true,
            message: '请输入确认密码！',
            type: 'error'
          })
          return
        }
        if (that.newPwd != that.surePwd) {
          Message({
            showClose: true,
            message: '确认密码不正确，请重新输入！',
            type: 'error'
          })
          return
        }
        that.$api.getForgetPwdModify({
          mobile:that.phone,
          password:that.newPwd
        }).then(res=>{
          if (res.data.code == 0) {
            Message({
              showClose: true,
              message: '密码修改成功，快去登录吧！',
              type: 'success'
            })
            setTimeout(()=>{
              this.$router.push('/login');
              this.$store.commit('changClick',1)
            },2000)
          }
        })
      }
    }
}
</script>

<style scoped lang="scss">
.main-resetPwd{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .views{
        width: 427px;
        height: 396px;
        background: #FCFCFC;
        margin-top: 50px;
        margin-bottom: 175px;
        .title{
            font-size: 24px;
            line-height: 34px;
            color: #333333;
            margin-left: 20px;
            margin-top: 30px;
        }
        .zh-title{
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 20px;
            margin-top: 40px;
        }
        .mm-title{
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 20px;
            margin-top: 20px;
        }
        .login{
            width: 387px;
            height: 42px;
            margin-left: 20px;
            margin-top: 40px;
            border-radius: 4px;
            font-size: 16px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
        }
        .wj-title{
            width: 60px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #666;
            cursor: pointer;
            margin-left: 347px;
            margin-top: 20px;
        }
    }
}
.input-title{
    width: 387px;
    height: 40px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none; //去点击蓝色边框
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    line-height: 40px;
    color: #666666;
    margin-left: 20px;
    margin-top: 10px;
}
</style>